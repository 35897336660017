<template>
    <div>
        <v-dialog 
        v-model="localQuotesByClientsDialog" 
        persistent 
        scrollable
        fullscreen
        >
            <v-card>
                <v-card-title style="background-color: #eeeeee; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);">
                    <v-row justify="space-between">
                        <v-col cols="11">
                            <span>Quotes by Clients</span>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-end">
                            <v-btn
                                depressed
                                color="primary"
                                icon
                                rounded
                                @click="closeLocalQuotesByClientsDialog"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <!--Usd Quotes-->
                    <v-row no-gutters>
                        <v-col cols="12" xs="12" sm="12" md="6">
                            <div class="mt-5" style="max-height: 600px; overflow-y: auto;">
                                <v-data-table
                                    :headers="quotesByClientsHeaders"
                                    :items="filteredQuotesByClientsUsd"
                                    disable-pagination
                                    hide-default-footer
                                    :mobile-breakpoint="0"
                                >
                                    <template v-slot:top>
                                        <v-toolbar
                                            flat
                                            no-gutters
                                            style="background-color: #eeeeee"
                                            :class="
                                                $vuetify.breakpoint.xs
                                                    ? 'd-flex flex-column align-items-center py-4'
                                                    : 'd-flex justify-between align-items-center py-4'
                                            "
                                        >
                                            <v-toolbar-title>Quotes by Clients (USD)</v-toolbar-title>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:header.name>
                                        <v-text-field
                                            v-model="usdSearch"
                                            label="Clients"
                                            append-icon="mdi-magnify"
                                            hide-details
                                            single-line
                                            dense
                                        />
                                    </template>

                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td>{{ item.name }}</td>
                                            <td @click="show0to60UsdDialog(item.name)">{{ formatUsd(item.total0To60) }}</td>
                                            <td @click="show60to180UsdDialog(item.name)">{{ formatUsd(item.total60To180) }}</td>
                                            <td @click="show180to360UsdDialog(item.name)">{{ formatUsd(item.total180To360) }}</td>
                                            <td @click="show360UsdOrMoreDialog(item.name)">{{ formatUsd(item.total360OrMore) }}</td>
                                        </tr>
                                    </template>

                                    <template v-slot:[`body.append`]>
                                        <tr style="background-color: #eeeeee;">
                                            <td><strong>Total</strong></td>
                                            <td>{{ formatUsd(getFilteredTotalCostsUsd('total0To60')) }}</td> 
                                            <td>{{ formatUsd(getFilteredTotalCostsUsd('total60To180')) }}</td> 
                                            <td>{{ formatUsd(getFilteredTotalCostsUsd('total180To360')) }}</td> 
                                            <td>{{ formatUsd(getFilteredTotalCostsUsd('total360OrMore')) }}</td> 
                                        </tr>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6">
                            <div style="margin-top: 20px;">
                                <highcharts :options="chartUsdQuotesByClients"></highcharts>
                            </div>
                        </v-col>
                    </v-row>
                    <!--Cop Quotes-->
                    <v-row no-gutters>
                        <v-col cols="12" xs="12" sm="12" md="6">
                            <div class="mt-5" style="max-height: 600px; overflow-y: auto;">
                                <v-data-table
                                    :headers="quotesByClientsHeaders"
                                    :items="filteredQuotesByClientsCop"
                                    disable-pagination
                                    hide-default-footer
                                    :mobile-breakpoint="0"
                                >
                                    <template v-slot:top>
                                        <v-toolbar
                                            flat
                                            no-gutters
                                            style="background-color: #eeeeee"
                                            :class="
                                                $vuetify.breakpoint.xs
                                                    ? 'd-flex flex-column align-items-center py-4'
                                                    : 'd-flex justify-between align-items-center py-4'
                                            "
                                        >
                                            <v-toolbar-title>Quotes by Clients (COP)</v-toolbar-title>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:header.name>
                                        <v-text-field
                                            v-model="copSearch"
                                            label="Clients"
                                            append-icon="mdi-magnify"
                                            hide-details
                                            single-line
                                            dense
                                        />
                                    </template>

                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td>{{ item.name }}</td>
                                            <td @click="show0to60CopDialog(item.name)">{{ formatCop(item.total0To60) }}</td>
                                            <td @click="show60to180CopDialog(item.name)">{{ formatCop(item.total60To180) }}</td>
                                            <td @click="show180to360CopDialog(item.name)">{{ formatCop(item.total180To360) }}</td>
                                            <td @click="show360CopOrMoreDialog(item.name)">{{ formatCop(item.total360OrMore) }}</td>
                                        </tr>
                                    </template>

                                    <template v-slot:[`body.append`]>
                                        <tr style="background-color: #eeeeee;">
                                            <td><strong>Total</strong></td>
                                            <td>{{ formatCop(getFilteredTotalCostsCop('total0To60')) }}</td> 
                                            <td>{{ formatCop(getFilteredTotalCostsCop('total60To180')) }}</td> 
                                            <td>{{ formatCop(getFilteredTotalCostsCop('total180To360')) }}</td> 
                                            <td>{{ formatCop(getFilteredTotalCostsCop('total360OrMore')) }}</td> 
                                        </tr>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6">
                            <div style="margin-top: 20px;">
                                <highcharts :options="chartCopQuotesByClients"></highcharts>
                            </div>
                        </v-col>
                    </v-row>


                </v-card-text>
            </v-card>
        </v-dialog> 

        <!--For USD Quotes Dialog-->
        <v-dialog
            v-model="quotesUsdDialog"
            max-width="1500px"
            :retain-focus="false"
        >
                <v-card>
                    <v-card-title v-if="from0To60UsdDialog" class="text-h5">
                        0 - 60 days (USD)
                    </v-card-title>
                    <v-card-title v-if="from60To180UsdDialog" class="text-h5">
                        60 - 180 days (USD)
                    </v-card-title>
                    <v-card-title v-if="from180To360UsdDialog" class="text-h5">
                        180 - 360 days (USD)
                    </v-card-title>
                    <v-card-title v-if="from360UsdOrMoreDialog" class="text-h5">
                        +360 days (USD)
                    </v-card-title>
                    <v-card-text v-if="from0To60UsdData">
                        <div style="max-height: 400px; overflow-y: auto;">
                            <v-data-table
                                :headers="headers"
                                :items="quotesByClients[selectedClientName]?.usdQuotes.quotes0To60 || []"
                                id="virtual-scroll-table"
                                disable-pagination
                                hide-default-footer
                                :mobile-breakpoint="0"
                                class="mt-2"          
                            >
                                <template v-slot:top>
                                    <v-toolbar flat style="background-color: #eeeeee !important">
                                        <v-toolbar-title>QUOTES</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                </template>

                                <template v-slot:[`item.userId`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(accountManager, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(accountManager) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:[`item.collaborators`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(collaborator, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(collaborator) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:footer>
                                    <v-simple-table>
                                        <tfoot class="grey lighten-3">
                                            <tr>
                                                <td class="font-weight-bold">TOTAL</td>
                                                <td class="font-weight-bold text-right">
                                                    {{ getTotalCosts(quotesByClients[selectedClientName].usdQuotes.quotes0To60) }} 
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </v-simple-table>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                    <v-card-text v-if="from60To180UsdData">
                        <div style="max-height: 400px; overflow-y: auto;">
                            <v-data-table
                                :headers="headers"
                                :items="quotesByClients[selectedClientName]?.usdQuotes.quotes60To180 || []"
                                id="virtual-scroll-table"
                                disable-pagination
                                hide-default-footer
                                :mobile-breakpoint="0"
                                class="mt-2"                
                            >
                                <template v-slot:top>
                                    <v-toolbar flat style="background-color: #eeeeee !important">
                                        <v-toolbar-title>QUOTES</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                </template>
                                
                                <template v-slot:[`item.userId`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(accountManager, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(accountManager) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:[`item.collaborators`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(collaborator, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(collaborator) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:footer>
                                    <v-simple-table>
                                        <tfoot class="grey lighten-3">
                                            <tr>
                                                <td class="font-weight-bold">TOTAL</td>
                                                <td class="font-weight-bold text-right">
                                                    {{ getTotalCosts(quotesByClients[selectedClientName].usdQuotes.quotes60To180) }} 
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </v-simple-table>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                    <v-card-text v-if="from180To360UsdData">
                        <div style="max-height: 400px; overflow-y: auto;">
                            <v-data-table
                                :headers="headers"
                                :items="quotesByClients[selectedClientName]?.usdQuotes.quotes180To360 || []"
                                id="virtual-scroll-table"
                                disable-pagination
                                hide-default-footer
                                :mobile-breakpoint="0"   
                                class="mt-2"             
                            >
                                <template v-slot:top>
                                    <v-toolbar flat style="background-color: #eeeeee !important">
                                        <v-toolbar-title>QUOTES</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                </template>

                                <template v-slot:[`item.userId`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(accountManager, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(accountManager) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:[`item.collaborators`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(collaborator, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(collaborator) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:footer>
                                    <v-simple-table>
                                        <tfoot class="grey lighten-3">
                                            <tr>
                                                <td class="font-weight-bold">TOTAL</td>
                                                <td class="font-weight-bold text-right">
                                                    {{ getTotalCosts(quotesByClients[selectedClientName].usdQuotes.quotes180To360) }} 
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </v-simple-table>
                                </template>
                            </v-data-table>
                        </div>    
                    </v-card-text>
                    <v-card-text v-if="from360UsdOrMoreData">
                        <div style="max-height: 400px; overflow-y: auto;">
                            <v-data-table
                                :headers="headers"
                                :items="quotesByClients[selectedClientName]?.usdQuotes.quotes360OrMore || []"
                                id="virtual-scroll-table"
                                disable-pagination
                                hide-default-footer
                                :mobile-breakpoint="0"   
                                class="mt-2"             
                            >
                                <template v-slot:top>
                                    <v-toolbar flat style="background-color: #eeeeee !important">
                                        <v-toolbar-title>QUOTES</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                </template>

                                <template v-slot:[`item.userId`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(accountManager, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(accountManager) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:[`item.collaborators`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(collaborator, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(collaborator) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:footer>
                                    <v-simple-table>
                                        <tfoot class="grey lighten-3">
                                            <tr>
                                                <td class="font-weight-bold">TOTAL</td>
                                                <td class="font-weight-bold text-right">
                                                    {{ getTotalCosts(quotesByClients[selectedClientName].usdQuotes.quotes360OrMore) }} 
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </v-simple-table>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text color="secondary" @click="closeDialog" class="mt-n4 mb-2">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
        </v-dialog>

        <!--For COP Quotes Dialog-->
        <v-dialog
            v-model="quotesCopDialog"
            max-width="1500px"
            :retain-focus="false"
        >
                <v-card>
                    <v-card-title v-if="from0To60CopDialog" class="text-h5">
                        0 - 60 days (COP)
                    </v-card-title>
                    <v-card-title v-if="from60To180CopDialog" class="text-h5">
                        60 - 180 days (COP)
                    </v-card-title>
                    <v-card-title v-if="from180To360CopDialog" class="text-h5">
                        180 - 360 days (COP)
                    </v-card-title>
                    <v-card-title v-if="from360CopOrMoreDialog" class="text-h5">
                        +360 days (COP)
                    </v-card-title>
                    <v-card-text v-if="from0To60CopData">
                        <div style="max-height: 400px; overflow-y: auto;">
                            <v-data-table
                                :headers="headers"
                                :items="quotesByClients[selectedClientName]?.copQuotes.quotes0To60 || []"
                                id="virtual-scroll-table"
                                disable-pagination
                                hide-default-footer
                                :mobile-breakpoint="0"
                                class="mt-2"          
                            >
                                <template v-slot:top>
                                    <v-toolbar flat style="background-color: #eeeeee !important">
                                        <v-toolbar-title>QUOTES</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                </template>

                                <template v-slot:[`item.userId`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(accountManager, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(accountManager) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:[`item.collaborators`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(collaborator, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(collaborator) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:footer>
                                    <v-simple-table>
                                        <tfoot class="grey lighten-3">
                                            <tr>
                                                <td class="font-weight-bold">TOTAL</td>
                                                <td class="font-weight-bold text-right">
                                                    {{ getTotalCosts(quotesByClients[selectedClientName].copQuotes.quotes0To60) }} 
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </v-simple-table>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                    <v-card-text v-if="from60To180CopData">
                        <div style="max-height: 400px; overflow-y: auto;">
                            <v-data-table
                                :headers="headers"
                                :items="quotesByClients[selectedClientName]?.copQuotes.quotes60To180 || []"
                                id="virtual-scroll-table"
                                disable-pagination
                                hide-default-footer
                                :mobile-breakpoint="0"
                                class="mt-2"                
                            >
                                <template v-slot:top>
                                    <v-toolbar flat style="background-color: #eeeeee !important">
                                        <v-toolbar-title>QUOTES</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                </template>
                                
                                <template v-slot:[`item.userId`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(accountManager, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(accountManager) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:[`item.collaborators`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(collaborator, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(collaborator) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:footer>
                                    <v-simple-table>
                                        <tfoot class="grey lighten-3">
                                            <tr>
                                                <td class="font-weight-bold">TOTAL</td>
                                                <td class="font-weight-bold text-right">
                                                    {{ getTotalCosts(quotesByClients[selectedClientName].copQuotes.quotes60To180) }} 
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </v-simple-table>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                    <v-card-text v-if="from180To360CopData">
                        <div style="max-height: 400px; overflow-y: auto;">
                            <v-data-table
                                :headers="headers"
                                :items="quotesByClients[selectedClientName]?.copQuotes.quotes180To360 || []"
                                id="virtual-scroll-table"
                                disable-pagination
                                hide-default-footer
                                :mobile-breakpoint="0"   
                                class="mt-2"             
                            >
                                <template v-slot:top>
                                    <v-toolbar flat style="background-color: #eeeeee !important">
                                        <v-toolbar-title>QUOTES</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                </template>

                                <template v-slot:[`item.userId`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(accountManager, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(accountManager) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:[`item.collaborators`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(collaborator, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(collaborator) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:footer>
                                    <v-simple-table>
                                        <tfoot class="grey lighten-3">
                                            <tr>
                                                <td class="font-weight-bold">TOTAL</td>
                                                <td class="font-weight-bold text-right">
                                                    {{ getTotalCosts(quotesByClients[selectedClientName].copQuotes.quotes180To360) }} 
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </v-simple-table>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                    <v-card-text v-if="from360CopOrMoreData">
                        <div style="max-height: 400px; overflow-y: auto;">
                            <v-data-table
                                :headers="headers"
                                :items="quotesByClients[selectedClientName]?.copQuotes.quotes360OrMore || []"
                                id="virtual-scroll-table"
                                disable-pagination
                                hide-default-footer
                                :mobile-breakpoint="0"   
                                class="mt-2"             
                            >
                                <template v-slot:top>
                                    <v-toolbar flat style="background-color: #eeeeee !important">
                                        <v-toolbar-title>QUOTES</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                </template>

                                <template v-slot:[`item.userId`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(accountManager, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(accountManager) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:[`item.collaborators`]="{ item }">
                                    <div class="my-4">
                                        <p
                                            v-for="(collaborator, index) of item.collaborators"
                                            :key="index"
                                            class="my-0 mx-0"
                                        >
                                            {{ getUserName(collaborator) }}
                                        </p>
                                    </div>
                                </template>

                                <template v-slot:footer>
                                    <v-simple-table>
                                        <tfoot class="grey lighten-3">
                                            <tr>
                                                <td class="font-weight-bold">TOTAL</td>
                                                <td class="font-weight-bold text-right">
                                                    {{ getTotalCosts(quotesByClients[selectedClientName].copQuotes.quotes360OrMore) }} 
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </v-simple-table>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text color="secondary" @click="closeDialog" class="mt-n4 mb-2">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { Chart } from 'highcharts-vue'
export default {
    name: 'QuotesByClients',
    props: {
        openQuotesByClientsDialog: {
            type: Boolean,
            required: true,
        },
        quotes: {
            type: Array,
            default() {
                return []
            },
        },
        users: {
            type: Array,
            required: true
        },
    },
    components: {
        highcharts: Chart,
    },
    data() {
        return {
            height: 0,
            localQuotesByClientsDialog: this.openQuotesByClientsDialog,
            quotesData: [],
            quotesByClients: {},
            quotesUsdDialog: false,
            quotesCopDialog: false,
            from0To60UsdDialog: false,
            from60To180UsdDialog: false,
            from360UsdOrMoreDialog: false,
            from0To60UsdData: false,
            from60To180UsdData: false,
            from180To360UsdData: false,
            from180To360UsdDialog: false,
            from360UsdOrMoreData: false,
            from0To60CopDialog: false,
            from60To180CopDialog: false,
            from360CopOrMoreDialog: false,
            from0To60CopData: false,
            from60To180CopData: false,
            from180To360CopData: false,
            from180To360CopDialog: false,
            from360CopOrMoreData: false,
            selectedClientName: null,
            chartUsdQuotesByClients: {},
            chartCopQuotesByClients: {},
            usdSearch: "",
            copSearch: "",
            quotesByClientsHeaders: [
                { text: 'Clients', value: 'name', sortable: false },
                { text: '0 - 60 days', value: 'total0To60' },
                { text: '60 - 180 days', value: 'total60To180' },
                { text: '180 - 360 days', value: 'total180To360' },
                { text: '+360 days', value: 'total360OrMore' },
            ],
            headers: [
                {
                    text: 'ID',
                    value: 'number',
                    align: 'center',
                },
                {
                    text: 'Name',
                    value: 'name',
                    align: 'center',
                },
                {
                    text: 'Client',
                    value: 'client.name',
                    align: 'center',
                },
                {
                    text: 'City',
                    value: 'city',
                    align: 'center',
                },
                {
                    text: 'Currency',
                    value: 'currency',
                    align: 'center',
                },
                {
                    text: 'Price',
                    value: 'totalCosts',
                    align: 'center',
                },
                {
                    text: 'Account Manager',
                    value: 'userId',
                    align: 'center',
                },
                {
                    text: 'Collaborators',
                    value: 'collaborators',
                    align: 'center',
                },
            ],
        }
    },
    computed: {
        ...mapState(['costQuotes',]),
        formattedQuotesByClientsUsd() {
            return Object.keys(this.quotesByClients).map(clientName => {
                return {
                name: clientName,
                total0To60: this.getTotalCosts(this.quotesByClients[clientName].usdQuotes.quotes0To60),
                total60To180: this.getTotalCosts(this.quotesByClients[clientName].usdQuotes.quotes60To180),
                total180To360: this.getTotalCosts(this.quotesByClients[clientName].usdQuotes.quotes180To360),
                total360OrMore: this.getTotalCosts(this.quotesByClients[clientName].usdQuotes.quotes360OrMore),
                };
            });
        },
        filteredQuotesByClientsUsd() {
            if (!this.usdSearch) {
                return this.formattedQuotesByClientsUsd;
            }
            return this.formattedQuotesByClientsUsd.filter((quote) =>
                quote.name.toLowerCase().includes(this.usdSearch.toLowerCase())
            );
        },
        formattedQuotesByClientsCop() {
            return Object.keys(this.quotesByClients).map(clientName => {
                return {
                name: clientName,
                total0To60: this.getTotalCosts(this.quotesByClients[clientName].copQuotes.quotes0To60),
                total60To180: this.getTotalCosts(this.quotesByClients[clientName].copQuotes.quotes60To180),
                total180To360: this.getTotalCosts(this.quotesByClients[clientName].copQuotes.quotes180To360),
                total360OrMore: this.getTotalCosts(this.quotesByClients[clientName].copQuotes.quotes360OrMore),
                };
            });
        },
        filteredQuotesByClientsCop() {
            if (!this.copSearch) {
                return this.formattedQuotesByClientsCop;
            }
            return this.formattedQuotesByClientsCop.filter((quote) =>
                quote.name.toLowerCase().includes(this.copSearch.toLowerCase())
            );
        },
    },
    watch: {
        openQuotesByClientsDialog(val) {
            this.localQuotesByClientsDialog = val;
        },
        localQuotesByClientsDialog(val) {
            this.$emit('update:openQuotesByClientsDialog', val); 
        },
        filteredQuotesByClientsUsd() {
            this.createUsdPieChart(); 
        },
        filteredQuotesByClientsCop() {
            this.createCopPieChart(); 
        },
    },
    async mounted() {
        this.filterByDateRange()

    },
    methods: {
        ...mapMutations(['setErrorItems']),
        getTotalCosts(quotes) {
            return quotes.reduce((total, quote) => {
                return total + (quote.totalCosts || 0); 
            }, 0);
        },
        getFilteredTotalCostsUsd(key) {
            return this.filteredQuotesByClientsUsd.reduce((sum, client) => sum + client[key], 0);
        },
        getFilteredTotalCostsCop(key) {
            return this.filteredQuotesByClientsCop.reduce((sum, client) => sum + client[key], 0);
        },
        getUserName(id) {
            if (this.users.length > 0) {
                const userReference = this.users.find(user => user.id == id)
                if (userReference) {
                    return userReference.name
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        formatUsd(value) {
            return new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'USD', 
            }).format(value);
        },
        formatCop(value) {
            return new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'COP', 
            }).format(value);
        },
        filterByDateRange() {
            this.quotesByClients = {};
            const currentDate = new Date();

            const limit60Days = new Date();
            limit60Days.setDate(currentDate.getDate() - 60);
            
            const limit180Days = new Date();
            limit180Days.setDate(currentDate.getDate() - 180);

            const limit360Days = new Date();
            limit360Days.setDate(currentDate.getDate() - 360);

            const allQuotes = Object.values(this.quotes).flat();

            for (let quote of allQuotes) {
                if (quote.sentDate) {
                    const sentDate = new Date(quote.sentDate._seconds * 1000 + quote.sentDate._nanoseconds / 1000000);
                    const clientName = quote.client.name;

                    if (!this.quotesByClients[clientName]) {
                        this.quotesByClients[clientName] = {
                            usdQuotes: {
                                quotes0To60: [],
                                quotes60To180: [],
                                quotes180To360: [],
                                quotes360OrMore: []
                            },
                            copQuotes: {
                                quotes0To60: [],
                                quotes60To180: [],
                                quotes180To360: [],
                                quotes360OrMore: []
                            },
                        };
                    }

                    // Categorize by date range and currency
                    if (sentDate >= limit60Days) {
                        // for USD Quotes
                        if (quote.currency == 'USD') {
                            this.quotesByClients[clientName].usdQuotes.quotes0To60.push(quote);
                        }
                        // for COP Quotes
                        else if (quote.currency == 'COP') {
                            this.quotesByClients[clientName].copQuotes.quotes0To60.push(quote);
                        }
                    } else if (sentDate < limit60Days && sentDate >= limit180Days) {
                        // for USD Quotes
                        if (quote.currency == 'USD') {
                            this.quotesByClients[clientName].usdQuotes.quotes60To180.push(quote);
                        }
                        // for COP Quotes
                        else if (quote.currency == 'COP') {
                            this.quotesByClients[clientName].copQuotes.quotes60To180.push(quote);
                        }
                    } else if (sentDate < limit180Days && sentDate >= limit360Days) {
                        // for USD Quotes
                        if (quote.currency == 'USD') {
                            this.quotesByClients[clientName].usdQuotes.quotes180To360.push(quote);
                        }
                        // for COP Quotes
                        else if (quote.currency == 'COP') {
                            this.quotesByClients[clientName].copQuotes.quotes180To360.push(quote);
                        }
                    } else if (sentDate < limit360Days) {
                        // for USD Quotes
                        if (quote.currency == 'USD') {
                            this.quotesByClients[clientName].usdQuotes.quotes360OrMore.push(quote);
                        }
                        // for COP Quotes
                        else if (quote.currency == 'COP') {
                            this.quotesByClients[clientName].copQuotes.quotes360OrMore.push(quote);
                        }
                    }
                }
            }
            return this.quotesByClients;
        },
        createUsdPieChart() {
            const data = [
                {
                name: "0 - 60 days",
                y: this.getFilteredTotalCostsUsd("total0To60"),
                color: '#23bf85',
                },
                {
                name: "60 - 180 days",
                y: this.getFilteredTotalCostsUsd("total60To180"),
                color: '#efbd21',
                },
                {
                name: "180 - 360 days",
                y: this.getFilteredTotalCostsUsd("total180To360"),
                color: "orange",
                },
                {
                name: "+360 days",
                y: this.getFilteredTotalCostsUsd("total360OrMore"),
                color: '#db4d42',
                },
            ];

            this.chartUsdQuotesByClients = {
                chart: {
                type: "pie",
                },
                title: {
                text: "Total Costs by Days Range (USD)",
                },
                series: [
                {
                    name: "Total Costs",
                    colorByPoint: true,
                    data: data,
                },
                ],
                credits: {
                    enabled: false, 
                },
                plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                    },
                },
                },
            };
        },
        createCopPieChart() {
            const data = [
                {
                name: "0 - 60 days",
                y: this.getFilteredTotalCostsCop("total0To60"),
                color: '#23bf85',
                },
                {
                name: "60 - 180 days",
                y: this.getFilteredTotalCostsCop("total60To180"),
                color: '#efbd21',
                },
                {
                name: "180 - 360 days",
                y: this.getFilteredTotalCostsCop("total180To360"),
                color: "orange",
                },
                {
                name: "+360 days",
                y: this.getFilteredTotalCostsCop("total360OrMore"),
                color: '#db4d42',
                },
            ];

            this.chartCopQuotesByClients = {
                chart: {
                type: "pie",
                },
                title: {
                text: "Total Costs by Days Range (COP)",
                },
                series: [
                {
                    name: "Total Costs",
                    colorByPoint: true,
                    data: data,
                },
                ],
                credits: {
                    enabled: false, 
                },
                plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                    },
                },
                },
            };
        },
        closeLocalQuotesByClientsDialog() {
            this.localQuotesByClientsDialog = false
        },
        // USD quotes dialogs control
        show0to60UsdDialog(clientName) {
            this.selectedClientName = clientName
            this.quotesUsdDialog = true
            this.from0To60UsdDialog = true
            this.from0To60UsdData = true
            this.from60To180UsdDialog = false
            this.from180To360UsdDialog = false
            this.from360UsdOrMoreDialog = false
            this.from60To180UsdData = false
            this.from180To360UsdData = false
            this.from360UsdOrMoreData = false
        },
        show60to180UsdDialog(clientName) {
            this.selectedClientName = clientName
            this.quotesUsdDialog = true
            this.from60To180UsdDialog = true
            this.from60To180UsdData = true
            this.from0To60UsdDialog = false
            this.from180To360UsdDialog = false
            this.from360UsdOrMoreDialog = false
            this.from0To60UsdData = false
            this.from180To360UsdData = false
            this.from360UsdOrMoreData = false
        },
        show180to360UsdDialog(clientName) {
            this.selectedClientName = clientName
            this.quotesUsdDialog = true
            this.from180To360UsdDialog = true
            this.from180To360UsdData = true
            this.from0To60UsdDialog = false
            this.from60To180UsdDialog = false
            this.from360UsdOrMoreDialog = false
            this.from0To60UsdData = false
            this.from60To180UsdData = false
            this.from360UsdOrMoreData = false
        },
        show360UsdOrMoreDialog(clientName) {
            this.selectedClientName = clientName
            this.quotesUsdDialog = true
            this.from360UsdOrMoreDialog = true
            this.from360UsdOrMoreData = true
            this.from0To60UsdDialog = false
            this.from60To180UsdDialog = false
            this.from180To360UsdDialog = false
            this.from0To60UsdData = false
            this.from60To180UsdData = false
            this.from180To360UsdData = false
        },
        // COP quotes dialogs control
        show0to60CopDialog(clientName) {
            this.selectedClientName = clientName
            this.quotesCopDialog = true
            this.from0To60CopDialog = true
            this.from0To60CopData = true
            this.from60To180CopDialog = false
            this.from180To360CopDialog = false
            this.from360CopOrMoreDialog = false
            this.from60To180CopData = false
            this.from180To360CopData = false
            this.from360CopOrMoreData = false
        },
        show60to180CopDialog(clientName) {
            this.selectedClientName = clientName
            this.quotesCopDialog = true
            this.from60To180CopDialog = true
            this.from60To180CopData = true
            this.from0To60CopDialog = false
            this.from180To360CopDialog = false
            this.from360CopOrMoreDialog = false
            this.from0To60CopData = false
            this.from180To360CopData = false
            this.from360CopOrMoreData = false
        },
        show180to360CopDialog(clientName) {
            this.selectedClientName = clientName
            this.quotesCopDialog = true
            this.from180To360CopDialog = true
            this.from180To360CopData = true
            this.from0To60CopDialog = false
            this.from60To180CopDialog = false
            this.from360CopOrMoreDialog = false
            this.from0To60CopData = false
            this.from60To180CopData = false
            this.from360CopOrMoreData = false
        },
        show360CopOrMoreDialog(clientName) {
            this.selectedClientName = clientName
            this.quotesCopDialog = true
            this.from360CopOrMoreDialog = true
            this.from360CopOrMoreData = true
            this.from0To60CopDialog = false
            this.from60To180CopDialog = false
            this.from180To360CopDialog = false
            this.from0To60CopData = false
            this.from60To180CopData = false
            this.from180To360CopData = false
        },
        closeDialog() {
            this.quotesUsdDialog = false
            this.quotesCopDialog = false
            // usd dialogs
            this.from0To60UsdDialog = false
            this.from60To180UsdDialog = false
            this.from180To360UsdDialog = false
            this.from360UsdOrMoreDialog = false
            this.from0To60UsdData = false
            this.from60To180UsdData = false
            this.from180To360UsdData = false
            this.from360UsdOrMoreData = false
            // cop dialogs 
            this.from0To60CopDialog = false
            this.from60To180CopDialog = false
            this.from180To360CopDialog = false
            this.from360CopOrMoreDialog = false
            this.from0To60CopData = false
            this.from60To180CopData = false
            this.from180To360CopData = false
            this.from360CopOrMoreData = false
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead th {
    background-color: #eeeeee !important;
}
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.item_class {
    background-color: #3385d7 !important;
    color: white !important;
}
</style>